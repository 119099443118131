<template>
  <div>
    <v-tabs centered v-model="FieldTab">
        <v-tab >Field</v-tab>
        <v-tab-item>
            <v-card-text>
             <v-text-field outlined dense placeholder="Option Name"
            label="Type in Field name"
            v-model="OptionField.Name" :rules="[$store.state.formrules.required]"/>
            <v-text-field outlined dense placeholder="Option Icon" 
            :prepend-icon="OptionField.Icon"
            label="Select Icon for the Field (Optional)"
            v-model="OptionField.Icon"/>
             <v-combobox item-text="Name" multiple outlined dense chips placeholder="Press Enter to include"
            label="Type in options"
            v-model="OptionField.Options" :rules="[$store.state.formrules.required]">
                <template v-slot:selection="{ item }">
                    <v-chip @click="ActivateOptionConfig(item)">
                        {{item.Name ? item.Name : item}}
                    </v-chip>
                    <!-- <v-list-item v-for="item in data" :key="item.itemObjKey">
                        {{item.Name ? item.Name : data}}
                    </v-list-item> -->
                </template>
             </v-combobox>
            </v-card-text>
             <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="SaveOptionField(OptionField)">Save</v-btn>
            </v-card-actions>  
        </v-tab-item>
        <v-tab>Option Config
            <!--  :disabled="!OptionField.id" -->
        </v-tab>
        <v-tab-item>
     <CleanOptionConfig :AddTitle="AddTitle" :AddHint="'You will be able to edit this later as well'" :CategoryField="OptionField"
     :NewOption="NewOption"
        :NewOptionLevel="NewOptionLevel" @SaveOption="SaveOption" :ParentOption="ParentOption" @CancelNewOptionDialog="CancelNewOptionDialog"
        :PluginOptions="PluginOptions"
        />
        </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import CleanOptionConfig from '@/components/Database/Fields/Input/CleanOptionConfig'

export default {
    props: ['System','SystemEntities','OptionField','PluginOptions'],
    components: {CleanOptionConfig},
    data() {
        return {
            NewOption: {
                Name: '',
                Description: '',
                Icon: '',
                Color: '#FF0000'
            },
            FieldTab: 0,
            ParentOption: '',
            NewOptionLevel: 1,
            ParentFilter: '',
        }
    },	
    computed:{
        AddTitle(){
            return this.NewOptionLevel === 1 ? 'Add an Option' : 'Add an Option to "'+this.ParentOption.DisplayName+'"'
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateOptionConfig(item){
            if(item.Name){
                this.NewOption = {
                    Name: item.Name,
                    DisplayName: item.DisplayName,
                    ID: item.ID,
                }
            }
            else{
                this.NewOption = {
                    Name: item,
                    DisplayName: item,
                }
            }
            this.FieldTab = 1
        },
        CancelFieldDialog(){
            this.$emit('CancelFieldDialog')
        },
       CancelNewOptionDialog(){
            this.FieldTab = 0
            this.NewOptionLevel = 1            
            this.ParentOption = ''
        },
        SaveOptionField(){
           this.$emit('SaveOptionField',this.OptionField)
        },
        SaveOption(NewOption,lvl,parent){
            this.OptionField.lastassignedinteger = this.OptionField.lastassignedinteger-1+2
            let newoption = {
                Name: NewOption.Name,
                DisplayName: NewOption.Name,
                AdditionalOptions: [],
                ID: this.OptionField.lastassignedinteger
            }
            let props = ['Description','Icon','Color','Pluginid','Templateid','TemplateOptions']
            props.map(prp => {
                if(typeof NewOption[prp] !== 'undefined'){
                    newoption[prp] = NewOption[prp]
                }
            })
            let level = this.OptionField.Levels[lvl-1]
            newoption.Level = level
            newoption.LevelFieldName = level.FieldName
            if(lvl < this.OptionField.LastLevel){
                newoption.Options = []
                newoption.HasDeepHierarchy
            }
            if(lvl === 1){
               this.OptionField.Options.push(newoption) 
            }
            else if(lvl !== 1){
                parent.Options.push(newoption)
            }
            //this.NewOptionName = ''
            //thus the below shows only issue is knowing which level we wokr from, easy
            //HasDeepHierarchy is lvl not exceeding
            //Level (fullobj)
            //LevelFieldName (txt)
            //Options (IFHasDeepHierarchy)

           // this.SaveOptionField() ??
            
        },
    }
}
</script>

<style>

</style>



